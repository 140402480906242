/**
 * index.js
 * Handles all component renders
 * Author: Benni Delgado
 * AIR
 */
import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.css";
import App from "./components/App";
import About from "./components/About";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function AppRouter() {
	return (
		<Router>
			<Switch>
				<Route path="/about">
					<About />
				</Route>
				<Route path="/">
					<App />
				</Route>
			</Switch>
		</Router>
	);
}

ReactDOM.render(<AppRouter />, document.getElementById("root"));

serviceWorker.unregister();
