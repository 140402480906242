import React, { useState } from "react";
import { Accordion, AccordionActions, AccordionSummary, List, ListItem } from "@material-ui/core";
import ArrowDropUpRoundedIcon from "@material-ui/icons/ArrowDropUpRounded";
import ColorBarImage from "../assets/colorbar.png";

export default function SideLegend() {
	const [open, setOpen] = useState(false);

	return (
		<div className="sidelegend">
			<Accordion onClick={() => setOpen(!open)}>
				<AccordionSummary expandIcon={<ArrowDropUpRoundedIcon />}>Legend</AccordionSummary>
				<AccordionActions>
					<List>
						<ListItem dense={true}>
							<img src={ColorBarImage} width={"45%"} alt="colorbar" />
						</ListItem>
					</List>
				</AccordionActions>
			</Accordion>
		</div>
	);
}
