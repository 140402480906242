import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, Typography, Container, createMuiTheme, ThemeProvider } from "@material-ui/core";
import Logo from "../assets/logo.png";
import BackgroundImage from "../assets/background.png";

const darkTheme = createMuiTheme({
	palette: {
		type: "dark",
	},
});

const useStyles = makeStyles((theme) => ({
	icon: {
		marginRight: theme.spacing(2),
	},
	heroContent: {
		backgroundImage: `url(${BackgroundImage})`,
		backgroundColor: "rgba(0,0,0,0.7)",
		backgroundBlendMode: "multiply",
		padding: theme.spacing(8, 0, 6),
	},
	heroButtons: {
		marginTop: theme.spacing(4),
	},
	bodyContent: {
		backgroundColor: "#0a1929",
		color: "rgba(255,255,255,1)",
	},
	appBar: {
		backgroundColor: "#001e3c",
	},
	logo: {
		maxWidth: 300,
	},
}));

export default function Album() {
	const classes = useStyles();

	return (
		<ThemeProvider theme={darkTheme}>
			<div id="about-page">
				<AppBar className={classes.appBar} position="relative">
					<Toolbar>
						<a href="https://environment.arizona.edu">
							<img src={Logo} alt="logo" className={classes.logo} />
						</a>
					</Toolbar>
				</AppBar>

				{/* Hero unit */}
				<div className={classes.heroContent}>
					<Container  maxWidth="sm">
						<Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
							Monsoon Visualizer
						</Typography>
						<Typography variant="h5" align="center" color="textSecondary" paragraph>
							A data aggregation and visualization project displaying sensors from various networks around
							Arizona
						</Typography>
					</Container>
				</div>
				<div className={classes.bodyContent}>
					<br />
					<Container maxWidth="sm">
						<Typography variant="h4" align="center" color="textPrimary">
							What is it?
						</Typography>
						<hr />
						<Typography variant="body1" component="span" color="textSecondary">
							The Monsoon Visualizer project aggregates precipitation sensor data from the{" "}
							<a href="https://alertmap.rfcd.pima.gov/gmap/gmap.html">Pima County FCD Network</a>,{" "}
							<a href="https://alert.fcd.maricopa.gov/alert/Google/v3/gmap.html">Maricopa FCD Network</a>,{" "}
							<a href="https://mohave.onerain.com/">Mohave County FCD Network</a>,{" "}
							<a href="https://rainlog.org/">Rainlog Network</a>, and the{" "}
							<a href="https://mesowest.utah.edu/">Mesowest Network</a> to generates various geospatial
							data visualizations. It is developed and maintained by the{" "}
							<a href="https://environment.arizona.edu">Arizona Institutes for Resilience</a> at the
							University of Arizona.
						</Typography>
						<br />
						<br />

						<Typography variant="h4" align="center" color="textPrimary">
							How To Use
						</Typography>
						<hr />
						<Typography variant="h5" align="center" color="textPrimary">
							Generating a Map
						</Typography>
						<Typography variant="body1" component="span" color="textSecondary">
							Located at the top left of the website is the "Generate" button. In this menu you are able
							to select the date(s) along with one or more sensor networks you would like to view. If a
							date range is selected, the data shown will be the total amount of rain for each day summed
							together for each sensor. Please note that date ranges must be less than a year in length,
							see the API section for further access of our datasets.
						</Typography>
						<br />
						<br />

						<Typography variant="h5" align="center" color="textPrimary">
							Viewing Table Data
						</Typography>
						<Typography variant="body1" component="span" color="textSecondary">
							Located in the bottom right of the website is the "Table" button. This will pull up a table
							of all of the sensors and their corresponding data that is currently being shown on the
							visualizer. You are able to export this data via the "Export to CSV" button which will
							download a CSV file containing the total rainfall (in.), longitude, latitude, network, and
							the sensor name for each sensor that is currently being shown on the map. You are also able
							to click on any one sensor on the table to view it on the map.
						</Typography>
						<br />
						<br />

						<Typography variant="h5" align="center" color="textPrimary">
							Map Options
						</Typography>
						<Typography variant="body1" component="span" color="textSecondary">
							Located in the bottom left of the website is the "Settings" button. This will pull up the
							various map options that can be custimized to your liking. These settings include the
							various map styles which alter the view of the base Mapbox map shown, as well as the various
							Deck.gl "layers". A layer is simply just a way to display the data, for example, the "Hex
							layer" will render each sensor as a three dimensional hexagon and will grow vertically based
							on how much rain the sensor recieved.
							<br />
							An important layer to note here is the MRMS layer which <u>does not</u> alter the
							visualization of the sensors, rather it overlays gridded data provided by{" "}
							<a href="https://www.nssl.noaa.gov/projects/mrms/">NOAA's MRMRS network</a>. Please note that due to the size of these datasets
                            we only offer the past week of gridded data
						</Typography>
					</Container>
				</div>
			</div>
		</ThemeProvider>
	);
}
