/**
 * api.ts
 * Utility class for interfacing with the API. Includes functions for
 * authentication, querying the main route, querying the mrms route, and getting
 * an rgb color from the colorscale.
 *
 * Author: Benni Delgado
 * AIR
 */
import axios from "axios";
import moment from "moment";
import { FloodResponseItem, LoginResponse, MapResponseItem } from "./monsoon-types";
import { interpolateGnBu } from "d3-scale-chromatic";
import { scaleSequential } from "d3-scale";
import { color } from "d3-color";

// api info
const API_URL = "https://api.air.arizona.edu";

axios.defaults.headers.common["access-control-allow-origin"] = "*";

/**
 * /login route
 * @param username
 * @param key
 * @returns
 */
export async function login(
	username: string,
	key: string
): Promise<LoginResponse> {
	const params = {
		username: username,
		key: key,
	};

	const url = API_URL + "/login";

	const resp = await axios.post(url, params);
	return resp.data;
}

export async function genMap(
	networks: string[],
	startDate: moment.Moment,
	endDate: moment.Moment,
	key: string
): Promise<MapResponseItem[]> {
	// form search params
	const params = new URLSearchParams({
		startDate: startDate.format("YYYY-MM-DD"),
		endDate: endDate.format("YYYY-MM-DD"),
		network: networks.join("-"),
	});
	// form url
	const url = API_URL + "/?" + params.toString();
	//form headers
	const headers = {
		headers: {
			Authorization: `Bearer ${key}`,
		},
	};

	const resp = await axios.get(url, headers);
	const data = resp.data.sort((a, b) => {
		return b.total_rainfall - a.total_rainfall;
	});
	return data;
}

/**
 * Given a start date and
 *
 * @param startDate {moment.Moment} moment start date
 * @param key {string} API token
 * @returns
 */
export async function genMrms(startDate: moment.Moment, key: string) {
	const params = new URLSearchParams({
		date: startDate.format("YYYY-MM-DD"),
	});

	const url = API_URL + "/mrms?" + params.toString();

	const headers = {
		headers: {
			Authorization: `Bearer ${key}`,
		},
	};

	const resp = await axios.get(url, headers);
	return resp.data;
}

export async function genFlood(
	startDate: moment.Moment,
	endDate: moment.Moment,
	networks: string[],
	key: string
) : Promise<FloodResponseItem[]> {
    const params = new URLSearchParams({
        startDate: startDate.format("YYYY-MM-DD"),
        endDate: endDate.format("YYYY-MM-DD"),
        network: networks.join("-"),
    });

    const url = API_URL + "/flood?" + params.toString();

    const headers = {
        headers: {
            Authorization: `Bearer ${key}`
        }
    };

    const resp = await axios.get(url, headers);
    return resp.data;
}

/**
 * returns an array of [r,g,b] values representing the color on an
 * interpolated Green -> Blue sequential colorscale
 *
 * @param rainfall {float} represents total rainfall
 * @returns
 */
export function getColor(rainfall: number) {
	// scaling from 0in to 15in
	const scale = scaleSequential(interpolateGnBu).domain([0, 15]);
	const rainColor = color(scale(rainfall));
	return [rainColor.r, rainColor.g, rainColor.b];
}

export function getColorFlood(waterLevel: number) {
    /**
     * @NOTE I dont know what the min and max are for these gauges, especially
     * because we get some weird negative values for the water level, so I'm just
     * going to set a minimum amount of 0 and a max amount of 5 feet
     */
    const scale = scaleSequential(interpolateGnBu).domain([0, 5]);
    const waterColor = color(scale(waterLevel));
    return [waterColor.r, waterColor.g, waterColor.b];
}
