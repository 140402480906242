/**
 * SummaryTable.tsx
 * Component for displaying all of the datapoints sorted by the max total rainfall
 * in decending order. Click events are sent to the parent to generate a new
 * viewstate.
 *
 * Author: Benni Delgado
 * AIR
 */
import React, { useMemo, useState } from "react";
import {
	Button,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Drawer,
} from "@material-ui/core";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import { MapResponseItem, GoToPointCallback } from "../utils/monsoon-types";
import { parse } from "json2csv";

const cols = [
	{ id: "sensor_name", numeric: false, label: "Sensor" },
	{ id: "total_rainfall", numeric: true, label: "Total Rainfall (in.)" },
	{ id: "network", numeric: false, label: "Network" },
	{ id: "lon", numeric: true, label: "Longitude" },
	{ id: "lat", numeric: true, label: "Latitude" },
];

interface SummaryTableProps {
	data: MapResponseItem[];
	goToPoint: GoToPointCallback;
}

export default function SummaryTable(props: SummaryTableProps) {
	const [open, setOpen] = useState(false);
	/**
	 * I noticed that as our dataset got larger (n >= 500) the table
	 * struggled to be re-rendered each time it was closed and opened.
	 * Here we'll just cache the tablebody as it doesnt need to be recomputed
	 * each time the component renders if the data doesn't change.
	 * Downside is it creates larger memory overhead but the performance
	 * benefits outweigh the memory cons.
	 */
	const ComputedTableBody = useMemo(() => {
		return props.data.map((row, idx) => (
			<TableRow onClick={(e) => goto(e.currentTarget)} hover key={idx}>
				<TableCell>{row.sensor_name}</TableCell>
				<TableCell>{row.total_rainfall}</TableCell>
				<TableCell>{row.network}</TableCell>
				<TableCell>{row.lon}</TableCell>
				<TableCell>{row.lat}</TableCell>
			</TableRow>
		));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.data]);

	// event handler for going to specific point on the map, reports to props
	const goto = (currTarget: any) => {
		const lon = parseFloat(currTarget.children[3].innerHTML);
		const lat = parseFloat(currTarget.children[4].innerHTML);
		// goto prop
		props.goToPoint(lon, lat);
	};

	// function for generating a blob for the csv
	const genCSV = () => {
		const fields = [
			"lat",
			"lon",
			"network",
			"sensor_name",
			"total_rainfall",
		];
		const csv = parse(props.data, { fields });

		const file = new Blob([csv], { type: "text/plain" });
		return file;
	};

	return (
		<div className="table-button">
			<Button
				color="secondary"
				variant="contained"
				onClick={() => setOpen(!open)}
				size="medium"
			>
				<MenuBookIcon />
			</Button>
			<Drawer
				variant="temporary"
				anchor="bottom"
				elevation={12}
				open={open}
				onClose={() => setOpen(false)}
				onKeyDown={() => setOpen(false)}
			>
				<TableContainer component={Paper} style={{ maxHeight: 400 }}>
					<Table stickyHeader aria-label="summary-report">
						<TableHead>
							<TableRow>
								<TableCell align="center" colSpan={10}>
									<a
										href={URL.createObjectURL(genCSV())}
										download="rainfall.csv"
									>
										Export To CSV
									</a>
								</TableCell>
							</TableRow>
							<TableRow>
								{cols.map((col) => (
									<TableCell key={col.id}>
										{col.label}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>{ComputedTableBody}</TableBody>
					</Table>
				</TableContainer>
			</Drawer>
		</div>
	);
}
