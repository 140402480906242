/**
 * monsoon-types.ts
 * All of the relvant types used throughout the plotter.
 * @NOTE This isn't a d.ts file because there's currently a bug in TS where you
 *       are unable to export enums.
 * 
 * Author: Benni Delgado
 * AIR
 */
import moment from "moment";

// API TYPES
export type Network =
	| "pima_fcd"
	| "maricopa_fcd"
	| "mohave_fcd"
	| "rainlog"
	| "mesowest"
	| string;

export interface LoginResponse {
	accessToken: string;
}

export interface MapResponseItem {
	lat: number;
	lon: number;
	network: Network;
	sensor_name: string;
	total_rainfall: number;
}

export interface MrmsResponseItem {
    lat: number;
	lon: number;
	precipitation: number;
}

export interface FloodResponseItem {
    sensor_name: string;
    lat: number;
    lon: number;
    feet: number;
    cfs: number;
    anomaly_checksum: string;
}

export interface RainfallQueryParams {
    networks: Network[];
	startDate: moment.Moment;
	endDate: moment.Moment;
    showFlood: boolean;
}

// event handler callbacks
export type HandleGenerateMapCallback = (RainfallQueryParams) => void;
export type GoToPointCallback = (lon: number, lat: number) => void;
export type UpdateOptionsCallback = (newOptions: MapOptions) => void;

// toast error types
export enum ToastErrorType {
	INFO = "info",
	SUCCESS = "success",
	ERROR = "error",
}

//icon mapping
export const ICON_MAPPING = {
	pima_fcd: { x: 0, y: 0, width: 142, height: 180, mask: true },
	rainlog: { x: 205, y: 0, width: 142, height: 180, mask: true },
	maricopa_fcd: { x: 410, y: 0, width: 142, height: 180, mask: true },
	mohave_fcd: { x: 615, y: 0, width: 142, height: 180, mask: true },
	mesowest: { x: 820, y: 0, width: 142, height: 180, mask: true },
    flood: {x: 1025, y:0, width: 184, height: 180, mask: true}
};

// deck layer types will add more as the are supported
export interface LayerTypes {
	icon: boolean;
	heat: boolean;
	hex: boolean;
	mrms: boolean;
}

// type for all the mapbox styles, keyed by their url then the label as the value
export const MapboxStyles = {
	"mapbox://styles/gbdelgado/ckuix881dfoq918oepmcf5dzo": "Monsoon Visualizer",
	"mapbox://styles/mapbox/streets-v10": "Streets V10",
	"mapbox://styles/mapbox/outdoors-v10": "Outdoors V10",
	"mapbox://styles/mapbox/light-v9": "Light V9",
	"mapbox://styles/mapbox/dark-v9": "Dark V9",
	"mapbox://styles/mapbox/satellite-v9": "Satellite V9",
	"mapbox://styles/mapbox/satellite-streets-v10": "Satellite Streets V10",
	"mapbox://styles/mapbox/navigation-preview-day-v4":
		"Navigation Preview Day V4",
	"mapbox://styles/mapbox/navigation-preview-night-v4":
		"Navigation Preview Night V4",
	"mapbox://styles/mapbox/navigation-guidance-day-v4":
		"Navigation Guidance Day V4",
	"mapbox://styles/mapbox/navigation-guidance-night-v4":
		"Navigation Guidance Night V4",
};

export interface MapOptions {
	mapStyle: string;
	layers: LayerTypes;
}

