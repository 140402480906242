/**
 * OptionsMenu.tsx
 * This component is responsible for rendering the various map options.
 * All form data is controlled/sent up to the parent for the processing and
 * business logic.
 *
 * Author: Benni Delgado
 * AIR
 */
import React, { useState, useEffect } from "react";
import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	DialogActions,
	FormControlLabel,
	IconButton,
	InputLabel,
	Select,
	Checkbox,
	MenuItem,
	Tooltip,
} from "@material-ui/core";
import {
	MapboxStyles,
	MapOptions,
	UpdateOptionsCallback,
} from "../utils/monsoon-types";
import CloseIcon from "@material-ui/icons/Close";
import InfoIcon from "@material-ui/icons/Info";

interface OptionsMenuProps {
	initalMapOptions: MapOptions;
	updateOptions: UpdateOptionsCallback;
}

export default function OptionsMenu(props: OptionsMenuProps) {
	const [open, setOpen] = useState(false);
	// only null because we get the inital value from props
	const [mapOptions, setMapOptions] = useState<MapOptions>({
		layers: { icon: true, heat: false, hex: false, mrms: false },
		mapStyle: "mapbox://styles/gbdelgado/ckuix881dfoq918oepmcf5dzo",
	});

	//set inital mapoptions from props
	useEffect(() => {
		setMapOptions(props.initalMapOptions);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="settings-menu">
			<Button
				size="medium"
				variant="contained"
				color="secondary"
				onClick={() => setOpen(!open)}
			>
				Options
			</Button>
			<Dialog
				open={open}
				onClose={() => setOpen(false)}
				fullWidth={true}
				maxWidth="md"
			>
				<DialogTitle>
					MapOptions
					<a href="/about">
						<Tooltip
							title={`Here you can control the various 'views' of the map
                            by changing the base map (shown below the visualization)
                            or by toggling the different layers. Click this tooltip
                            to go to our about page to learn more`}
							placement="right"
							arrow
							className="mrms-tooltip"
						>
							<InfoIcon />
						</Tooltip>
					</a>
					<IconButton
						onClick={() => setOpen(false)}
						size="small"
						style={{ position: "absolute", right: "1%" }}
					>
						<CloseIcon />
					</IconButton>
				</DialogTitle>

				<DialogContent>
					<InputLabel id="select-label">Map Style</InputLabel>
					<Select
						autoFocus
						value={mapOptions.mapStyle}
						onChange={(e) =>
							setMapOptions({
								...mapOptions,
								//@ts-ignore ignoring because strings lol
								mapStyle: e.target.value,
							})
						}
					>
						{Object.entries(MapboxStyles).map(
							([url, label], idx) => (
								<MenuItem key={idx} value={url}>
									{label}
								</MenuItem>
							)
						)}
					</Select>

					<br />
					{Object.keys(props.initalMapOptions.layers).map(
						(layer, idx) => (
							<div key={idx}>
								<FormControlLabel
									control={
										<Checkbox
											checked={mapOptions?.layers[layer]}
											onChange={(e) =>
												setMapOptions({
													mapStyle:
														mapOptions.mapStyle,
													layers: {
														...mapOptions.layers,
														[layer]:
															e.target.checked,
													},
												})
											}
											color="secondary"
										/>
									}
									label={
										// special case for MRMS
										layer === "mrms" ? (
											<div>
												MRMS Layer
												<Tooltip
													title="MRMS overlay is only available for the past seven days. If a date range is selected, MRMS overlay will represent the start date of the date range."
													placement="right"
													arrow
													className="mrms-tooltip"
												>
													<InfoIcon />
												</Tooltip>
											</div>
										) : (
											// capatilizing
											<div>
												{layer[0].toUpperCase() +
													layer.slice(1) +
													" Layer"}
											</div>
										)
									}
								/>
								<br />
							</div>
						)
					)}
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => {
							props.updateOptions(mapOptions);
							setOpen(!open);
						}}
						color="secondary"
						variant="contained"
					>
						Save
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
