/**
 * Map.tsx
 * This component is responsible for rendering all of the map visualizations and
 * deck layers. All data is controlled by the props to keep business logic out
 * of this component as much as possible.
 *
 * @NOTE I've disabled the TS compiler checks here because a lot of the deck-gl
 *       types aren't picked up by TS.
 *
 * Author: Benni Delgado
 * AIR
 */
//@ts-nocheck
import React, { useEffect, useState } from "react";
import { StaticMap } from "react-map-gl";
import DeckGL, {
	PickInfo,
	IconLayer,
	HeatmapLayer,
	HexagonLayer,
	GridLayer,
} from "deck.gl";
import {
	FloodResponseItem,
	ICON_MAPPING,
	MapOptions,
	MapResponseItem,
	MrmsResponseItem,
} from "../utils/monsoon-types";
import { getColor, getColorFlood } from "../utils/api";
import icons from "../assets/icons.png";

const colorRange = [
	[255, 255, 178, 25],
	[254, 217, 118, 85],
	[254, 178, 76, 127],
	[253, 141, 60, 170],
	[240, 59, 32, 212],
	[189, 0, 38, 255],
];
interface MapProps {
	data: MapResponseItem[];
	floodData: FloodResponseItem[];
	mapOptions: MapOptions;
	viewState: ViewStateProps;
	mrmsData?: MrmsResponseItem[];
}

/* eslint-disable react/no-deprecated */
export default function Map(props: MapProps) {
	const [pickInfo, setPickInfo] = useState<PickInfo>({});
	const [layers, setLayers] = useState([]);

	// to update layers on new data props
	useEffect(() => {
		const { layers } = props.mapOptions;
		setLayers([
			layers.icon &&
				new IconLayer({
					id: "icon-layer",
					data: props.data,
					iconMapping: ICON_MAPPING,
					iconAtlas: icons,
					getIcon: (d) => d.network,
					getSize: () => 35,
					getPosition: (d) => [parseFloat(d.lon), parseFloat(d.lat)],
					getColor: (d) => getColor(d.total_rainfall),
					onHover: onHover,
					pickable: true,
				}),
			props.floodData &&
				new IconLayer({
					id: "flood-layer",
					data: props.floodData,
					iconMapping: ICON_MAPPING,
					iconAtlas: icons,
					getIcon: () => "flood",
					getSize: () => 40,
					// We have to shift the flood gauges over a little bit so they're not
					// directly on top of the rainfall gauges
					getPosition: (d) => [
						parseFloat(d.lon) + 0.005,
						parseFloat(d.lat),
					],
					getColor: (d) => getColorFlood(d.feet),
					onHover: onHover,
					pickable: true,
				}),
			layers.heat &&
				new HeatmapLayer({
					id: "heatmap-layer",
					data: props.data,
					getPosition: (d) => [parseFloat(d.lon), parseFloat(d.lat)],
					getWeight: (d) => parseFloat(d.total_rainfall),
					onHover: onHover,
				}),
			layers.hex &&
				new HexagonLayer({
					id: "hex-layer",
					data: props.data,
					getPosition: (d) => [parseFloat(d.lon), parseFloat(d.lat)],
					getColorWeight: (d) => parseFloat(d.total_rainfall),
					getElevationWeight: (d) => parseFloat(d.total_rainfall),
					elevationScale: 4,
					pickable: true,
					extruded: true,
					radius: 700,
					onHover: onHover,
				}),
			layers.mrms &&
				new GridLayer({
					id: "mrms-layer",
					data: props.mrmsData,
					opacity: 0.3,
					getPosition: (d) => [parseFloat(d.lon), parseFloat(d.lat)],
					getColorWeight: (d) => parseFloat(d.precipitation),
					cellSize: 4000,
					colorRange,
					gpuAggregation: true,
					colorAggregation: "MIN",
				}),
		]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.data, props.mapOptions.layers, props.floodData]);

	// set state each picked object
	const onHover = (picked: PickInfo) => {
		setPickInfo(picked);
	};

	const renderHover = () => {
		// renaming because deck uses object for some reason which is reserved
		const { x, y, object: hovered } = pickInfo;
		if (!hovered) return;

		// set position, special case for 3d layers
		const styleProps = {
			position: "absolute",
			left: x,
			top: y,
			width: "200px",
		};

		// few different cases for the different types of layers/sensors
		let RenderedTooltip;
		// hex layer
		if (hovered.points) {
			RenderedTooltip = (
				<div className="map-tooltip" style={styleProps}>
					<b>Sensor: {hovered.points[0].source.sensor_name}</b>
					<br />
					<b>
						Total Rainfall:{" "}
						{hovered.points[0].source.total_rainfall.toFixed(3)}
					</b>
					<br />
					<b>Network: {hovered.points[0].source.network}</b>
				</div>
			);
			// flood gauges, must check both types of equality because the empty string is falsy
		} else if (hovered.anomaly_checksum || hovered.anomaly_checksum === "") {
			RenderedTooltip = (
				<div className="map-tooltip" style={styleProps}>
					<b>Sensor: {hovered.sensor_name}</b>
					<br />
					<b>
						Avg. Water Level: {hovered.feet.toFixed(1)} ft.
					</b>
                    <br />
                    <b>
                        Avg. Flow: {hovered.cfs ?? 0} feet<sup>3</sup>/sec.
                    </b>
                    <br />
                    <b>
                        Notes: {hovered.anomaly_checksum}
                    </b>
				</div>
			);
			// normal rain sensors
		} else {
			RenderedTooltip = (
				<div className="map-tooltip" style={styleProps}>
					<b>Sensor: {hovered.sensor_name}</b>
					<br />
					<b>
						Total Rainfall: {hovered.total_rainfall.toFixed(3)} in.
					</b>
					<br />
					<b>Network: {hovered.network}</b>
				</div>
			);
		}
		return RenderedTooltip;
	};

	return (
		<DeckGL
			layers={layers}
			initialViewState={props.viewState}
			controller={true}
		>
			<StaticMap
				mapboxApiAccessToken={process.env.REACT_APP_MAPBOXKEY}
				reuseMaps
				mapStyle={props.mapOptions.mapStyle}
				preventStyleDiffing={false}
			/>
			{renderHover}
		</DeckGL>
	);
}
