/**
 * LoadingScreen.tsx
 * Simple loading screen for when the app is loading
 * 
 * Author: Benni Delgado
 * AIR
 */
import * as React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import LinearProgress from '@material-ui/core/LinearProgress';

interface LoadingScreenProps {
	loading: boolean;
}

export default function LoadingScreen(props: LoadingScreenProps) {
	return (
		<Backdrop className="loading" open={props.loading}>
			<LinearProgress color="secondary" style={{width: "50%"}}/>
		</Backdrop>
	);
}
