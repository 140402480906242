/**
 * App.tsx
 * Highlevel composition of the Website
 *
 * Author: Benni Delgado
 * AIR
 */

import React, { useState, useEffect } from "react";
import { login, genMap, genMrms, genFlood } from "../utils/api";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { ToastContainer, toast, ToastOptions } from "react-toastify";
import { InitialViewStateProps } from "@deck.gl/core/lib/deck";
import { FlyToInterpolator } from "deck.gl";
import moment from "moment";

// components
import Map from "./Map";
import LoadingScreen from "./LoadingScreen";
import GenerateMenu from "./GenerateMenu";
import OptionsMenu from "./OptionsMenu";

// types
import {
    FloodResponseItem,
	MapOptions,
	MapResponseItem,
	MrmsResponseItem,
	RainfallQueryParams,
	ToastErrorType,
} from "../utils/monsoon-types";

// css
import "react-toastify/dist/ReactToastify.css";
import "../index.css";
import "mapbox-gl/dist/mapbox-gl.css";
import SideLegend from "./SideLegend";
import SummaryTable from "./SummaryTable";

const darkTheme = createMuiTheme({
	palette: {
		type: "dark",
	},
});

// centered about Tucson, Arizona
const INITIAL_VIEW_STATE = {
	longitude: -110.92,
	latitude: 32.27,
	zoom: 10,
	maxZoom: 20,
	pitch: 0,
	bearing: 0,
};

export default function App() {
	//@TODO all of this state is kind of messy, will change to a reducer soon
	const [accessToken, setAccessToken] = useState("");
	const [loading, setLoading] = useState(false);
	const [rainData, setRainData] = useState<MapResponseItem[]>([]);
    const [floodData, setFloodData] = useState<FloodResponseItem[]>([]);
	const [mrms, setMrms] = useState<MrmsResponseItem[]>([]);
	const [mapOptions, setMapOptions] = useState<MapOptions>({
		layers: { icon: true, heat: false, hex: false, mrms: false },
		mapStyle: "mapbox://styles/gbdelgado/ckuix881dfoq918oepmcf5dzo",
	});
	const [notify, setNotify] = useState(false);
	const [viewState, setViewState] =
		useState<InitialViewStateProps>(INITIAL_VIEW_STATE);

	// Component setup, collect access token
	useEffect(() => {
		(async () => {
			//expecting string | undefined error
			//@ts-expect-error
			const token = await login("plotter", process.env.REACT_APP_API_KEY);
			setAccessToken(token.accessToken);
		})();
		if (accessToken) {
			handleGenerateMap({
				startDate: moment().subtract(1, "day"),
				endDate: moment().subtract(1, "day"),
				networks: ["pima_fcd"],
                showFlood: false
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accessToken]);

	//////// Event handlers /////////
	/**
	 * Invokes the map generation function specified in api.ts. Performs error
	 * checking if the user incorrectly submitted the form as well as sets the
	 * state after all the data has been loaded.
	 *
	 * @param params {RainfallQueryParams} paramers for query, see types
	 * @returns void
	 */
	const handleGenerateMap = async (params: RainfallQueryParams) => {
		const { startDate, endDate, networks, showFlood } = params;
		// validate dates
		if (!startDate || !endDate) {
			throwToast("Invalid Date", ToastErrorType.ERROR);
			return;
		} else if (startDate.isAfter(endDate)) {
			throwToast(
				"Start date cannot be after the end date",
				ToastErrorType.ERROR
			);
			return;
		} else if (endDate.diff(startDate, "years", true) > 1) {
			throwToast(
				"Cannot request a date range that exceeds a year",
				ToastErrorType.ERROR
			);
			return;
		} else if (startDate.isAfter(moment()) || endDate.isAfter(moment())) {
			throwToast("Dates cannot be in the future", ToastErrorType.ERROR);
			return;
		}
		if (networks.length <= 0) {
			throwToast(
				"At least one network must be selected",
				ToastErrorType.ERROR
			);
			return;
		}

		// start loading
		setLoading(true);

		setRainData(await genMap(networks, startDate, endDate, accessToken));

		// check for mrms
		if (mapOptions.layers.mrms) {
			throwToast(
				"Requesting gridded data. This may take a while",
				ToastErrorType.INFO
			);
			try {
				setMrms(await genMrms(startDate, accessToken));
			} catch (err) {
				throwToast(
					"No gridded data availble for selected date",
					ToastErrorType.ERROR
				);
			}
		}

        // check for flood gauges
        if(showFlood) {
            // @NOTE only pima and maricopa are supported rn
            const floodNets = networks.filter((net) => net === "pima_fcd" || net === "maricopa_fcd");
            setFloodData(await genFlood(startDate, endDate, floodNets, accessToken));
        }

		// end loading
		setLoading(false);
	};

	/**
	 * A Simple error throwing toast function. Throws the string contained in
	 * msg.
	 * @param {String} msg
	 */
	const throwToast = (msg: string, type: ToastErrorType) => {
		// default settings for the toast
		const toastOptions: ToastOptions = {
			position: "bottom-center",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		};

		switch (type) {
			case ToastErrorType.SUCCESS:
				toast.success(msg, toastOptions);
				break;

			case ToastErrorType.ERROR:
				toast.error(msg, toastOptions);
				break;

			// default toast will be info
			case ToastErrorType.INFO:
			default:
				toast.info(msg, toastOptions);
				break;
		}
	};

	/**
	 * Generates a new viewstate and FlyToInterpolator for the map to zoom in too.
	 * @param lon {float}
	 * @param lat {float}
	 */
	const goToPoint = (lon: number, lat: number) => {
		const newViewState = {
			longitude: lon,
			latitude: lat,
			zoom: 15,
			pitch: 0,
			bearing: 0,
			transitionDuration: 1000,
			transitionInterpolator: new FlyToInterpolator(),
		};

		setViewState(newViewState);
	};

	/**
	 * Invoked by OptionsMenu to report new map settings
	 *
	 * @param newOptions {MapOptions} params for the map options
	 */
	const updateOptions = (newOptions: MapOptions) => {
		// special case for gridded networks, need to request for data
		if (newOptions.layers.mrms) {
			throwToast(
				"Map must be re-generated for gridded data",
				ToastErrorType.INFO
			);
			setNotify(true);
		} else {
			// cancel any notifications animations if no data was requested
			// this is needed in the case that someone selects then deselects
			setNotify(false);
		}
		setMapOptions(newOptions);
	};

	return (
		<ThemeProvider theme={darkTheme}>
			<ToastContainer />
			<LoadingScreen loading={loading} />
			<div className="container-fluid">
				{/* detect when the user clicks on the notify prop and dismiss
                if there is one */}
				<div onClick={() => setNotify(false)}>
					<GenerateMenu
						notify={notify}
						generate={handleGenerateMap}
					/>
				</div>
				<SideLegend />
				<SummaryTable data={rainData} goToPoint={goToPoint} />
				<OptionsMenu
					updateOptions={updateOptions}
					initalMapOptions={mapOptions}
				/>
				<Map
					data={rainData}
                    floodData={floodData}
					viewState={viewState}
					mapOptions={mapOptions}
					mrmsData={mrms}
				/>
			</div>
		</ThemeProvider>
	);
}
