/**
 * GenerateMenu.tsx
 * Component for displaying which days/networks the map should generate. All
 * settings are sent to the parent App.tsx to be queried for.
 *
 * Author: Benni Delgado
 * AIR
 */
import React, { useState } from "react";
import {
	Radio,
	RadioGroup,
	FormControlLabel,
	Button,
	Checkbox,
	Dialog,
	FormControl,
	DialogTitle,
	DialogContent,
	DialogActions,
	List,
	ListItem,
	Tooltip,
} from "@material-ui/core";
import MenuOpenTwoToneIcon from "@material-ui/icons/MenuOpenTwoTone";
import InfoIcon from "@material-ui/icons/Info";
import { SingleDatePicker, DateRangePicker } from "react-dates";
import moment from "moment";

// css
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { HandleGenerateMapCallback } from "../utils/monsoon-types";

const networkOptions = [
	{
		displayName: "Pima",
		id: "pima_fcd",
	},
	{
		displayName: "Maricopa",
		id: "maricopa_fcd",
	},
	{
		displayName: "Mohave",
		id: "mohave_fcd",
	},
	{
		displayName: "Rainlog",
		id: "rainlog",
	},
	{
		displayName: "Mesowest",
		id: "mesowest",
	},
];

interface GenerateMenuProps {
	generate: HandleGenerateMapCallback;
	// bool if the border animation should be triggered
	notify?: boolean;
}

interface Networks {
	pima_fcd: boolean;
	maricopa_fcd: boolean;
	rainlog: boolean;
	mesowest: boolean;
	mohave_fcd: boolean;
}

export default function GenerateMenu(props: GenerateMenuProps) {
	const [single, setSingle] = useState(true);
	// @TODO use reducer, state is getting messy
	const [startDate, setStartDate] = useState(moment().subtract(1, "day"));
	const [endDate, setEndDate] = useState(moment().subtract(1, "day"));
	const [focused, setFocused] = useState(false);
	const [focusedInput, setFocusedInput] = useState(null);
	const [menuOpen, setMenuOpen] = useState(false);
	const [showFlood, setShowFlood] = useState(false);
	const [networks, setNetworks] = useState<Networks>({
		pima_fcd: true,
		maricopa_fcd: false,
		rainlog: false,
		mesowest: false,
		mohave_fcd: false,
	});

	// event handler for checkboxes
	const handleCheck = (e: any) => {
		setNetworks((prevNetworks: Networks) => {
			return {
				...prevNetworks,
				[e.target.name]: e.target.checked,
			};
		});
	};

	return (
		<>
			<Button
				className="generate-menu"
				/**
				 * this is kind of dirty, but its the only way I was able to
				 * override the mui class with an animation, hence why I am adding
				 * an ID instead of a class.
				 */
				id={props.notify ? "generate-button" : ""}
				size="medium"
				variant="contained"
				endIcon={<MenuOpenTwoToneIcon />}
				aria-label="generate"
				color="secondary"
				onClick={() => setMenuOpen(true)}
			>
				Generate
			</Button>

			<Dialog
				onClose={() => setMenuOpen(false)}
				open={menuOpen}
				fullWidth={true}
				maxWidth="md"
				scroll="paper"
			>
				<DialogTitle id="form-dialog-title">
					Generate a New Map
					<a href="/about">
						<Tooltip
							title="Simply select either a single date, or date range, and one or more networks to generate a new map! For more information click this tooltip to go to our about page."
							placement="right"
							arrow
							className="mrms-tooltip"
						>
							<InfoIcon />
						</Tooltip>
					</a>
				</DialogTitle>
				<DialogContent>
					<List>
						<FormControl>
							<ListItem>
								<RadioGroup row>
									<FormControlLabel
										value="single"
										control={
											<Radio
												checked={single}
												onClick={() => setSingle(true)}
											/>
										}
										label="Single Date"
										labelPlacement="top"
									/>
									<FormControlLabel
										value="range"
										control={
											<Radio
												checked={!single}
												onClick={() => setSingle(false)}
											/>
										}
										label="Date Range"
										labelPlacement="top"
									/>
								</RadioGroup>
							</ListItem>

							<ListItem>
								{single ? (
									<SingleDatePicker
										date={startDate}
										onDateChange={(date: moment.Moment) => {
											// both dates will be the same if only a
											// single date is chosen
											setStartDate(date);
											setEndDate(date);
										}}
										focused={focused}
										onFocusChange={({ focused }) =>
											setFocused(focused)
										}
										isOutsideRange={() => false}
										id="singleDatePicker"
									/>
								) : (
									<DateRangePicker
										startDate={startDate}
										startDateId="start-date"
										endDate={endDate}
										endDateId="end-date"
										onDatesChange={({
											startDate,
											endDate,
										}) => {
											setStartDate(startDate);
											setEndDate(endDate);
										}}
										focusedInput={focusedInput}
										onFocusChange={(focusedInput) =>
											setFocusedInput(focusedInput)
										}
										isOutsideRange={() => false}
									/>
								)}
							</ListItem>
							{(focused || focusedInput) && (
								<div id="spacer" style={{ marginTop: "50%" }} />
							)}
							<ListItem>
								{networkOptions.map((net, idx: number) => (
									<FormControlLabel
										key={idx}
										control={
											<Checkbox
												name={net.id}
												checked={networks[net.id]}
												onChange={handleCheck}
											/>
										}
										label={net.displayName}
									/>
								))}
							</ListItem>
						</FormControl>
						<ListItem>
							<FormControlLabel
								control={
									<Checkbox
										checked={showFlood}
										onChange={() =>
											setShowFlood(!showFlood)
										}
									/>
								}
								label={
									<span>
										Show flood gauges
										<Tooltip
											title="Flood gauges will only be shown for Pima and Maricopa, as these are currently the only networks we support for them."
											placement="right"
											arrow
											className="mrms-tooltip"
										>
											<InfoIcon />
										</Tooltip>
									</span>
								}
							/>
						</ListItem>
					</List>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => {
							// converts Networks object to an array containing
							// only the names of the networks that are going
							// to be queried
							const networkList = Object.entries(networks).reduce(
								//@ts-expect-error
								(acc, curr) => {
									const [net, select] = curr;
									return select ? [...acc, net] : acc;
								},
								[]
							);
							props.generate({
								startDate,
								endDate,
								networks: networkList,
                                showFlood: showFlood
							});
							setMenuOpen(false);
						}}
						color="secondary"
						variant="contained"
					>
						Generate
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
